
.header {
  border-bottom: 1px solid #38384f;
  padding: 1rem 1rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger {
  display: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.nav-menu {
  display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}

.nav-item {
  color: white;
  opacity: 75%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 1px;
  height: 100%;
  padding: 22px 0 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-logo {
  color: white;
  font-size: 30px;
}

.chevron-icon {
  display: none;
}



.menu-planet:hover {
  opacity: 100%;
  cursor: pointer;
}

.mobile-button-container {
  display: none;
}

/*tablet styles*/
@media (min-width: 481px) and (max-width: 800px) {

  .nav-menu {
    width: 100%;
  }

  .chevron-icon {
    display: none;
  }


  .menu-planet {
    font-size: 16px;
  }
  .navbar{
    flex-direction: column;
    align-items: center;
  }
}

/* mobile styles*/
@media (max-width: 480px) {

 

  .planet-icon {
    height: 20px;
    width: 20px;
    border-radius: 1rem;
    margin-right: 1rem;
  }

  .nav-logo {
    display: flex;
  }

  .fixed {
    position: fixed;
  }
  
  .nav-menu {
    position: fixed;
    left: -100%;
    top: 4.8rem;
    flex-direction: column;
    background-color: #070724;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    z-index: 1;
    padding-bottom: 3rem;
}

.nav-menu.active {
    left: 0;
}

.nav-item {
    padding: 0;
    font-size: 15px;
    opacity: 100%;
    width: 100%;
    height: 65px;
    justify-content: space-between;
    border-bottom: 1px solid #38384F;
    padding-bottom: 1rem;
}

.item-title {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}

.menu-planet {
  display: flex;
  align-self: center;
}

.chevron-icon {
  display: flex;
  margin-right: 1rem;
}

  .menu-icon {
    display: flex;
  }

  .hamburger {
    display: block;
    margin-right: 1rem;
  }

  button {
    cursor: pointer;
  }

  .header-toggle {
    border: none;
    background: none;
    display: flex;
    align-items: center;
  }

  .header-container {
    padding: 16px 24px;
  }
  .mobile-button-container {
    display: flex;
    flex-direction: row;
    padding: 0px 24px;
    justify-content: space-between;

    border-style: none none solid none;
    border-bottom-color: #38384f;
    border-bottom-width: 1px;
  }

  .mobile-button {
    color: white;
    opacity: 75%;
    font-size: 14px;
    background-color: transparent;

    font-family: 'League Spartan', sans-serif;
    font-weight: 500; 
    letter-spacing: +2.6px;
    text-transform: uppercase;
    border-style: none none solid none;
    border-bottom-width: 1px;
  }

  .mobile-button:hover {
    background-color: #38384f;
    cursor: pointer;
  }

  .mobile-button:nth-child(1) {
    padding: 20px 22px 20px 22px;
  }
  .mobile-button:nth-child(2) {
    padding: 20px 22px 20px 22px;
  }
  .mobile-button:nth-child(3) {
    padding: 20px 22px 20px 22px;
  }
}
