.App {
  font-size: 14px;
  
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #070724;
  background-image: url('/src/assets/background-stars.svg');

  font-family: 'League Spartan', sans-serif;
  font-weight: 100;
  
}

.main-container {
  margin: 125px 165px 56px 165px;
}

h1 {
  font-family: 'Antonio', sans-serif;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

h2 {
  font-family: 'Antonio', sans-serif;
  font-weight: 500;
  letter-spacing: -1.5px;
  text-transform: uppercase;
}

h3 {
    font-family: 'League Spartan', sans-serif;
    font-weight: 500; 
    letter-spacing: +2.6px;
    text-transform: uppercase;
}

h4 {
  font-family: 'League Spartan', sans-serif;
  font-weight: 700; 
  letter-spacing: +1px;
}

/*tablet styles*/
@media (min-width: 481px) and (max-width: 800px) {
  .main-container {
    margin: 36px 39px;
  }
}

/* mobile styles*/
@media (max-width: 480px) {
  .main-container {
    margin: 24px 47px;
  }
}

